<template>
  <div>
    <header class="custom__header">
      <nav class="navbar navbar-expand-lg py-3 bg-white" :class="{ 'sticky-nav': isSticky }">
        <div class="container">
          <a class="navbar-brand brand-logo m-0 position-relative z-1" href="/">
            <img src="../assets/images/evolve-logo.png" alt="" class="img-fluid" width="170">
          </a>
          <button class="navbar-toggler customMenu-bg px-2 bg-none border-0" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav w-100 justify-content-center align-items-center position-relative z-1">
              <li class="nav-item">
                <router-link class="nav-link fst-normal py-12 px-3 fw-medium text-center active" aria-current="page"
                  to="/">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  >About</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  to="/skill-hub">Skillhub</router-link>
              </li>
              <li class="nav-item">
                <span class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  to="/tech-hub">Techhub</span>
              </li>
              <li class="nav-item">
                <span class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  to="/ascep" disabled>ASCEP</span>
              </li>
              <li class="nav-item">
                <span class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  to="/affiliate" disabled>Affiliate</span>
              </li>
              <li class="nav-item">
                <router-link class="nav-link fst-normal py-12 px-3 fw-medium text-center" aria-current="page"
                  to="/contact">Contact</router-link>
              </li>
            </ul>

            <!-- login for mobile -->
            <div class="login-register d-block d-lg-none">
              <div class="login d-flex align-items-center justify-content-center mt-3 gap-4 gap-md-0">
                <a :href="vueUrl" type="button"
                  class="btnLogin py-2 px-4 loginBtn d-flex align-items-center gap-1 loginBtn"> Log
                  In</a>

                <GlobalButton title="Sign up" :clickUrl="vueUrl+'/register'" />
              </div>
            </div>
          </div>
          <div class="login-register d-none d-lg-block">
            <div class="login d-flex align-items-center gap-3">
              <a :href="vueUrl" type="button"
                class="btnLogin py-2 px-4 text-decoration-none loginBtn d-flex align-items-center gap-1 loginBtn">
                Log In</a>

              <GlobalButton title="Sign up" :clickUrl="vueUrl+'/register'" />
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import ApiClass from '@/api/api';
  import GlobalButton from "@/components/GlobalButton.vue";

  export default {
    name: "HeaderComponent",
    components: {
      GlobalButton,
    },
    data() {
      return {
        vueUrl:"",
        isSticky: false,
      };
    },
    mounted() {
      this.vueUrl = ApiClass.vueUrl
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        this.isSticky = scrollPosition > 0;
      },
    },
  };
</script>

<style scoped>
  .custom__header .loginBtn {
    background-color: #0000;
  }

  .py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .custom__header .loginBtn {
    border: none;
    color: #000000a6;
    transition: all 0.5s ease-in-out;
    border: 1px solid #5271ff;
    color: #5271ff;
    height: 45px;
    border-radius: 35px;
  }

  .custom__header .loginBtn:hover {
    color: var(--active-bg);
    background-color: var(--active-bg);
    color: #FFF;
  }

  .customMenu-bg:focus {
    box-shadow: none;
  }

  .customMenu-bg span {
    background-image: url('../assets/icons/Hamburger.svg');
  }

  /* .customMenu-bg {
    background: var(--active-bg);
  } */

  .custom__header .nav-link {
    line-height: 24px;
    font-size: 14px;
  }


  .sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);

  }

  :is(.navbar-nav .router-link-active, .navbar-nav .nav-link:hover ) {
    color: var(--active-bg);
  }
  .navbar-nav .router-link-active{
    font-weight: 600 !important;
  }
</style>