<template>
  <div>
      <div v-if="showPreloader" id="preloader" class="d-flex flex-column">
        <div class="spinner">
          <img src="../assets/images/new-home/blue-w-c.png" alt="Logo" class="preloader-logo">
        </div>
        <p class="text-white fw-semibold mt-4">Please Wait...</p>
      </div>

    <section class="hero_section position-relative comm_padding" :class="{ loaded: isLoaded }" id="container">
      <div class="bg-overlay position-absolute top-0 start-0 end-0 bottom-0"></div>
      <div class="container z-3 position-relative">
        <div class="row align-items-center gap-5 gap-sm-0">
          <div class="col-md-6 col-lg-6">
            <div class="left-content d-flex flex-column align-items-center align-items-md-start z-3">
              <div class="">
                  <img src="../assets/images/new-home/main-cap.svg" alt="">
              </div>
              <h1 class="fw-bold mb-2 text-center text-md-start">Death by <br>
                Boredom is a <br>
                Thing.</h1>
                <h1 class="fw-bold mb-4 text-center text-md-start text-main">Learn Something <br> Exciting</h1>
                <p class="mb-0 fw-semibold fs-3">Sick of feeling like a background character in your own life?</p>
              <p class="mb-4 text-center text-md-start">evolveair's got your back. We offer a ton of dope courses to level up your skills
              and make you a career boss. Land your dream job, flex on social media, and
              even earn some real dollars with our super amazing affiliate program. Ditch the
              snooze scroll and learn something awesome.</p>
              <div class="video-modal mb-3 d-flex gap-3 flex-wrap">
                <GlobalButton title="Enrol Now" clickUrl="skill-hub" />
                <!-- <span><img src="../assets/icons/play.svg" alt="" width="45" /> Watch Video</span> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="img_new">
              <img src="../assets/images/new-home/main-banner.png" alt="" class="w-100">
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="position: relative; padding-top: 56.25%;">
          <iframe
            src="https://customer-dzisyupxqdp5fmp2.cloudflarestream.com/c97debe0c5e2a17d29a8cbc768e2a73a/iframe?poster=https%3A%2F%2Fcustomer-dzisyupxqdp5fmp2.cloudflarestream.com%2Fc97debe0c5e2a17d29a8cbc768e2a73a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
            loading="lazy"
            style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div> -->
    </section>

    <section class="mt-5">
        <div class="container ">
          <div class="search_bar mb-5">
                <div class=" d-flex flex-column gap-4">
                    <div class="courses-detail d-flex gap-2 gap-sm-5 flex-wrap mb-4">
                      <span
                        v-for="(tab, index) in tabs"
                        :key="index"
                        class="fs-6 fw-medium tabs-class"
                      
                        @click="toggleTab(index)"
                        :class="{ 'active': activeTab === index }"
                        >{{ tab }}</span
                      >
                    </div>
                </div>
                
                <div class="h-100">
                  <div v-if="activeTab === 0" class="position-relative"> 
                    <div class="d-flex border rounded-pill py-1 ps-3 pe-1 mt-2 mb-4">
                      <img src="../assets/images/new-home/search-bar.svg" alt="" />
                      <input 
                        type="text" 
                        class="form-control border-0 s-input" 
                        placeholder="Find the course you're looking for" 
                        @click="toggleSuggestions" 
                      />
                      <GlobalButton title="Search" />
                    </div>
                    <div class="search-bar-list" v-show="showSuggestionsList">
                      <ul class="pl-0">
                        <li><span class="search-icon"></span>list</li>
                        <li><span class="search-icon"></span>list</li>
                        <li><span class="search-icon"></span>list</li>
                        <li><span class="search-icon"></span>list</li>
                        <li><span class="search-icon"></span>list</li>
                      </ul>
                    </div>
                    <div class="row gap-0 row-gap-4">
                      <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(value,index) in all_Data" :key="index" >              
                        <CourseCard :title="value.title" :price="parseFloat(value.price)" :og_image="value.og_image" />
                      </div>
                   </div>
                </div>

                <div v-if="activeTab === 1">
                  <div class="d-flex border rounded-pill py-1 ps-3 pe-1 mt-2 mb-4">

                    <img src="../assets/images/new-home/search-bar.svg" alt="" />
                    <input type="" class="form-control border-0" placeholder="Find the course you're looking for"/>
                    <GlobalButton title="Search" />
                  </div> 
                  <div class="row gap-0 row-gap-4">
                    <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(value,index) in all_Data" :key="index" >              
                      <CourseCard :title="value.title" :price="parseFloat(value.price)" :og_image="value.og_image" />
                    </div>
                 </div>
                </div>
                <div v-if="activeTab === 2" class="h-100"> 
                  <div class=" d-flex align-items-center justify-content-center h-100 pb-5">            
                      <TechHub />
                 </div>
                </div>
                <div v-if="activeTab === 3" class="h-100"> 
                  <div class=" d-flex align-items-center justify-content-center h-100 pb-5">            
                      <AscepView />
                 </div>
                </div>

              </div>
           </div>
         </div>
    </section>

    <!-- <section class="position-relative comm_padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="search_bar d-flex flex-column gap-4">
             <div>
            

                <div>
                  <div v-if="activeTab === 0"> 
                    <div>All courses data goes here</div>
                </div>

                <div v-if="activeTab === 1"> 
                  <div>All courses data goes here</div>
                </div>

              </div>

             </div>

              
              <div class="bottom-serach">
                <div class="d-flex border rounded-pill py-1 ps-3 pe-1">

                  <img src="../assets/images/new-home/search-bar.svg" alt="" />
                  <input type="" class="form-control border-0" placeholder="Find the course you're looking for"/>
                  <GlobalButton title="Search" />
                </div>
                <div class="row gap-0 row-gap-4">
                  <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(value,index) in all_Data" :key="index" >
                  
                    <CourseCard :title="value.title" :price="parseFloat(value.price)" :og_image="value.og_image" />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="module_section comm_padding bg__new">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6 col-lg-6">
            <img src="../assets/images/new-home/new_sec.png" alt="" class="w-100 rounded-3" height="">
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="module-content">
              <h3 class="fw-semibold fs-1 mb-4 lh-base text-center text-sm-start">Ready to Level Up Your Skills,
                Explore our extra offerings</h3>
              <!-- <div class="row"> -->
                <div class="d-flex flex-column gap-4 mb-4">
                    <div class="module_card d-flex flex-column flex-sm-row align-items-center align-items-md-start gap-4">
                      <img src="../assets/images/new-home/module-img-1.svg" alt="" width="90">
                      <div class="text-center text-sm-start">
                        <h5 class="fs-5 fw-semibold mb-2">Exclusive study material</h5>
                        <ul class="">
                          <li>Case Studies</li>
                          <li>References</li>
                          <li>Assignments</li>
                          <li>Many More</li>                          
                        </ul>
                      </div>
                    </div>
                    <div class="module_card d-flex flex-column flex-sm-row align-items-center align-items-md-start gap-4">
                      <img src="../assets/images/new-home/module-img-2.svg" alt="" width="90">
                      <div class="text-center text-sm-start">
                        <h5 class="fs-5 fw-semibold mb-2">Exclusive assessments
                          </h5>
                          <ul class="">
                            <li>Checkpoint</li>
                            <li>Unique assessment test</li>
                            <li>Unique assessment test</li>                      
                          </ul>
                      </div>
                    </div>
                </div>
              <div class="bottom_btn courses_btn">
                <GlobalButton title="Explore" clickUrl="skill-hub" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="trusted_partner carousel-container comm_padding">
      <div class="container">
        <div class="row ">
          <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base">Trusted By Teams And Learners From</h2>
          </div>
          <div class="col-lg-12">
            <carousel :items-to-show="5" :wrap-around="true" :autoplay="1000" :breakpoints="breakpoints1">
              <slide v-for="(partner, index) in partnerData" :key="index">
                <div class="partner-img">
                  <img :src="require('../assets/images/new-home/' + partner.imageUrl)" :alt="partner.altText" class=""
                    width="150" height="52" />
                </div>
              </slide>
              <template #addons>
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </section>

    <section class="comm_padding">
      <div class="container">
        <div class="row gap-4 gap-sm-0">
          <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base">Access <span class="New_text">amazingly crafted</span> Courses from <br>
              <span class="New_text">evolveair</span> that help you enhance your career and skills.</h2>
            <p class="mb-0 fs-6">Take your learning and earning journey to the next level.</p>
          </div>
          <div class="col-sm-6 col-lg-4">
            <div class="stats h-100 d-flex flex-column gap-4">
              <div v-for="(item, index) in StatData" :key="index"
                class="box-1 d-flex gap-2 align-items-center border p-3 rounded-4">
                <img :src="require('../assets/images/new-home/' + item.imageUrl)" :alt="item.altText">
                <h5 class="mb-0 stat_title">{{ item.title }}</h5>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-3 order-lg-2">
            <div class="img mt-3 mt-lg-0">
              <img src="../assets/images/new-home/teaching.svg" alt="" class="w-100">
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-1 order-lg-3">
            <div class="stats h-100 d-flex flex-column gap-4">
              <div v-for="(item, index) in StatNewData" :key="index"
                class="box-1 d-flex gap-2 align-items-center border p-3 rounded-4">
                <img :src="require('../assets/images/new-home/' + item.imageUrl)" :alt="item.altText">
                <h5 class="mb-0 stat_title">{{ item.title }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm_padding bg__new">
      <div class="container">
        <div class="row align-items-center gap-4 gap-lg-0">
          <div class="col-lg-7">
            <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
              <h2 class="fw-semibold fs-1 mb-1 lh-base">Level Up Your Hustle with</h2>
              <h2 class="New_text fw-semibold fs-1 mb-3 lh-base">evolveair's Next-Level Programs</h2>
              <p class="mb-0 fs-6">Grow your professional network, enhance your expertise, and earn money with each paid.
                enrollment.</p>
            </div>
            <div class="img">
              <img src="../assets/images/new-home/study.svg" alt="" class="w-100">
            </div>
          </div>
          <div class="col-lg-5">
            <div class="outter-wrap d-grid gap-3">
              <div class="main_wrapp d-flex flex-column gap-3 mt-5">
                <div class="inner_wrapp">
                  <div class="main-box d-flex flex-column gap-3 align-items-center align-items-sm-start">
                    <div class="box-img d-flex justify-content-center align-items-center rounded-4 p-2">
                      <img src="../assets/images/new-home/gear-solid.svg" alt="">
                    </div>
                    <h4 class="mb-0 fs-6 fw-semibold text-center text-sm-start">Skillhub: Learning
                      packages to become a coding ninja, Shakespearean writer, or social media slayer. We build future rockstars. #LevelUp</h4>
                  </div>
                </div>
                <div class="inner_wrapp">
                  <div class="main-box layer-icon d-flex flex-column gap-3 align-items-center align-items-sm-start">
                    <div class="box-img d-flex justify-content-center align-items-center rounded-4 p-2">
                      <img src="../assets/images/new-home/layer-group-solid.svg" alt="">
                    </div>
                    <h4 class="mb-0 fs-6 fw-semibold text-center text-sm-start">Share your knowledge
                      with the world and get
                      paid with Evolveair
                      Affiliate. Every time
                      someone signs up
                      through you, you earn
                      serious cash</h4>
                  </div>
                </div>
              </div>
              <div class="main_wrapp d-flex flex-column gap-3">
                <div class="inner_wrapp ">
                  <div class="main-box play-Icon d-flex flex-column gap-3 align-items-center align-items-sm-start">
                    <div class="box-img d-flex justify-content-center align-items-center rounded-4 p-2">
                      <img src="../assets/images/new-home/play.svg" alt="">
                    </div>
                    <h4 class="mb-0 fs-6 fw-semibold text-center text-sm-start">Techhub ditches basic
                      tutorials. We're your
                      advanced tech dojo,
                      transforming coding
                      grasshoppers into full-
                      fledged senseis.
                      Blockchain or deep
                      learning? We got your
                      knowledge bombs.
                      #LevelUpYourCode</h4>
                  </div>
                </div>
                <div class="inner_wrapp">
                  <div class="main-box comment-icon d-flex flex-column gap-3 align-items-center align-items-sm-start">
                    <div class="box-img d-flex justify-content-center align-items-center rounded-4 p-2">
                      <img src="../assets/images/new-home/comment-dots-solid.svg" alt="">
                    </div>
                    <h4 class="mb-0 fs-6 fw-semibold text-center text-sm-start">ASCEP an uniquely
                      designed 90 days skill
                      and career
                      enhancement program</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cta_section comm_padding">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <CtaView />
          </div>
        </div>
      </div>
    </section>
<!-- 
    <section class="comm_padding">
      
      <div class="container">
        <div class="row align-items-center mb-4 ">
          <div class="main-heading mb-2  mb-lg-5 col-lg-10 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base">Online Courses for <span class="New_text">Anyone, Anywhere</span></h2>
            <p class="mb-0 fs-6">You don't have to struggle alone, you've got our assistance and help.</p>
          </div>
          <div class="col-lg-2 courses_btn">
            <GlobalButton title="See all Courses" clickUrl="skill-hub" />
          </div>
        </div>
        <div class="row gap-0 row-gap-4">
          <div class="col-sm-6 col-lg-4 col-xl-3" v-for="(value,index) in all_Data" :key="index" >
          
            <CourseCard :title="value.title" :price="parseFloat(value.price)" :og_image="value.og_image" />
        </div>
      </div>
    </section> -->
    <section class="feedback comm_padding bg__new">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="main-heading mb-2  mb-lg-5 col-lg-10 text-center text-sm-start">
              <h2 class="fw-semibold fs-1 mb-3 lh-base">People Says <span class="New_text">#tbh</span></h2>
              <p class="mb-0 fs-6">The EvolveAir experience in Their words</p>
            </div>
            <div class="instructor_wrap row">
              <div class="carousel-container instructor">
                <carousel :items-to-show="3.10" snapAlign="center" :breakpoints="breakpoints2" :transition="500">
                  <slide v-for="(FeedData, index) in FeedbackCard" :key="index">
                    <!-- <FeedbackCard /> -->
                    <div class="slider-wrap w-100">
                      <div class="border-0 coursesNew FeedbackCard bg-white d-flex flex-column p-32">
                        <div class="d-flex justify-content-between align-items-start">
                          <div class="overflow-hidden d-flex align-items-center topCard">
                            <div>
                              <img :src="require('../assets/images/new-home/' + FeedData.img)"
                                class="img-fluid rounded-circle courserImg" alt="course1" width="70" height="70" />
                            </div>
                            <div class="FeedbackCard-body feedbackBd d-flex flex-column align-items-start gap-2">
                              <h4
                                class="InstructorTitle overflow-hidden  text-decoration-none text-capitalize fw-semibold fs-4 fst-normal m-0 text-black">
                                {{ FeedData.name }}</h4>
                              <p
                                class="InstructorProfess overflow-hidden  text-decoration-none text-capitalize fw-normal fs-6 fst-normal m-0 text-black">
                                {{ FeedData.profession }}</p>
                            </div>
                          </div>
                          <div class="">
                            <img src="../assets/icons/quote.svg" alt="">
                          </div>
                        </div>
                        <div class="studentComment">
                          <p class="fs-6 fst-normal fw-normal m-0 text-start">
                            "{{ FeedData.comment }}"
                          </p>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons>
                    <navigation />
                    <pagination />
                  </template>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="comm_padding">
      <div class="container">
        <div class="row gap-0 row-gap-4">
          <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base">You can be your own <span class="New_text">Guiding star</span>
              with our help.</h2>
            <p class="mb-0 fs-6">You don't have to struggle alone, you've got our assistance and help.</p>
          </div>
          <div class="col-lg-6 position-relative">
            <div class="instructor-card h-100 row box_main p-4 rounded-2 w-100 gap-0 row-gap-4">
              <div class="col-sm-6 col-lg-6 text-center text-sm-start">
                <h4 class="fw-semibold">Become An Instructor</h4>
                <p class="mb-4 fs-7">Share your passion for flight as an EvolveAir instructor!</p>
                <div class="courses_btn">
                  <GlobalButton title="Start teaching today" />
                </div>
              </div>
              <div class="col-sm-6 col-lg-6">
                <div class="main-instru-img position-absolute bottom-0">
                  <img src="../assets/images/new-home/group-teachers.webp" alt="" class="w-100">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 position-relative">
            <div class="instructor-card h-100 row box_main_2 p-4 rounded-2 w-100">
              <div class="col-sm-6 col-lg-6 text-center text-sm-start">
                <h4 class="fw-semibold">Access To Education</h4>
                <p class="mb-4 fs-7">Unleash your potential with EvolveAir!
                  Sign up for an account and unlock a
                  world of high-quality courses.
                </p>
                <div class="courses_btn">
                  <GlobalButton title="Register for free" />
                </div>
              </div>
              <div class="col-sm-6 col-lg-6">
                <div class="main-instru-img position-absolute">
                  <img src="../assets/images/new-home/laptop.png" alt="" class="w-100">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="blog_section comm_padding">
      <div class="container">
        <div class="row">
          <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base">Take a Look Our <span class="New_text">latest Posts</span></h2>
            <p class="mb-0 fs-6">We’re a leading marketplace platform for learning and teaching online.</p>
          </div>
          <BlogCard />
        </div>
      </div>
    </section>

    <!-- <section class="course__work comm_padding position-relative w-100">
      <div class="container">
        <div class="row gap-0 row-gap-4">
          <div class="main-heading mb-2 mb-sm-5 text-center text-sm-start">
            <h2 class="fw-semibold fs-1 mb-3 lh-base text-white">How Does It Work</h2>
            <p class="mb-0 fs-6 text-white">Select from a vast library of over 21,000 online video courses, with fresh additions introduced every month.</p>
          </div>
          <div class="col-sm-6 col-lg-4 position-relative new_boxx" v-for="(item, index) in CourseWork" :key="index">
            <div class="course-work d-flex flex-column align-items-center gap-4 gap-sm-0">
              <div class="bg__icon d-flex justify-content-center align-items-center mb-1 mb-sm-5 rounded-2">
                <img :src="require('../assets/images/new-home/' + item.imageUrl)" :alt="item.imageUrl" />
              </div>
              <div class="text text-center">
                <h4 class="fw-semibold lh-base  text-white">{{ item.title }}</h4>
                <p class="m-0 text-white">{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="joinUs comm_padding">
      <div class="container">
        <div class="row align-items-center  gap-0 row-gap-4">
          <div class="col-md-5 col-lg-5">
            <div class="imgWrap">
              <img src="../assets/images/new-home/new-study.png" alt="" class="img-fluid">
            </div>
          </div>
          <div class="col-md-7 col-lg-7">
            <div class="text-group text-center text-sm-start">
              <div class="joinus-text section_title d-flex flex-column gap-3 mb-3">
                <h2 class="fs-1 fst-normal fw-semibold m-0 ">E-learning got you down?</h2>
                <p class="m-0 titleBold fw-normal fs-4 fst-normal ">evolveair is your secret weapon to conquering problems
                  Learn at your own terms.</p>
                <p class="m-0 fw-normal fs-6 fst-normal sub__title ">
                  Master the skills necessary to initiate, grow, and execute your course. Discover new abilities, enhance existing interests, and immerse yourself in creativity. You may find something unexpected and exhilarating along the way.
                </p>
              </div>
              <div class="d-flex justify-content-center justify-content-sm-start">
                <GlobalButton title="Get Started" clickUrl="skill-hub" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiClass from '@/api/api';
  import GlobalButton from "@/components/GlobalButton.vue";
  import CourseCard from "@/components/CourseCard.vue";
  import TechHub from "@/components/TechHub.vue";
  import AscepView from "@/components/AscepView.vue";
  import BlogCard from "@/components/BlogCard.vue";
  import CtaView from "@/components/CtaView.vue";
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, } from 'vue3-carousel'
  import { ref, onMounted } from 'vue';
  import appConfig from "@/app.config";


  export default {
    page: {
    title: "Home",
    meta: [{ name: "description", content: appConfig.description}],
  },
    name: "HomeView",
    components: {
      GlobalButton,
      Carousel,
      Slide,
      Pagination,
      CourseCard,
      CtaView,
      BlogCard,
      TechHub,
      AscepView
      // FeedbackCard,
      // Navigation,
    },

    setup() {
    const isLoaded = ref(false);
    const showPreloader = ref(true);

    onMounted(() => {
      setTimeout(() => {
        isLoaded.value = true;

        // Hide preloader after content is loaded
        setTimeout(() => {
          showPreloader.value = false;
        }, 1000); // Delay to allow transition
      }, 3000); // Delay to simulate loading
    });

    return {
      isLoaded,
      showPreloader
    };
  },


    data() {
      return {
        showSuggestionsList: false,
        all_Data:null,
        tabs: ["All courses", "Skill hub", "Tech hub", "Ascep"],
        activeTab: 0, 
        breakpoints1: {
          0: {
            itemsToShow: 1.5,
            snapAlign: 'start',
          },
          700: {
            itemsToShow: 3,
            snapAlign: 'center',
          },
          1024: {
            itemsToShow: 5,
            snapAlign: 'start',
          },
        },
        breakpoints2: {
          0: {
            itemsToShow: 1,
            snapAlign: 'center',
          },
          700: {
            itemsToShow: 2,
            snapAlign: 'center',
          },
          1024: {
            itemsToShow: 3.10,
            snapAlign: 'start',
          },
        },
        CourseWork: [
          {
            imageUrl: "graduation-cap.svg",
            title: "Choose A Course",
            description: "Be a member of this wonderful , to learn , to share and to grow. We can do so much together, than being alone!"
          },
          {
            imageUrl: "cart-shopping.svg",
            title: "Purchase A Course",
            description: "Build your network, share skills, and open up on the Learning platformwhere you can be your whole self Forward Arrow"
          },
          {
            imageUrl: "book-open.svg",
            title: "Start Now",
            description: "Discover new skills, meet passionate teachers and become a part of the most helpful community of creatives in the world."
          },
        ],
        FeedbackCard: [
          {
            img: "avatar-01.webp",
            name: "Emily Parker",
            profession: "UI-UX Designer",
            comment: 'EvolveAir offers a seamless learning experience with its user-friendly interface and diverse course offerings. The interactive features make studying engaging and enjoyable. Highly recommend for students seeking quality online education.',
          },
          {
            img: "avatar-02.webp",
            name: "Jamal Ahmed",
            profession: "Frontend Developer",
            comment: "I am impressed with EvolveAir's educational portal. The resources provided are comprehensive, and the instructors are knowledgeable. The platform's flexibility allows me to study at my own pace, making learning convenient.",
          },
          {
            img: "avatar-03.webp",
            name: "Sarah Johnson",
            profession: "Backend Developer",
            comment: "EvolveAir's educational portal has been a game-changer for me. The intuitive design makes navigation a breeze, and the content is top-notch. I appreciate the constant updates and support from the dedicated team. Worth every penny!",
          },


        ],
        partnerData: [
          {
            imageUrl: "Partner-1.png",
            altText: "Partner 1"
          },
          {
            imageUrl: "Partner-2.png",
            altText: "Partner 2"
          },
          {
            imageUrl: "Partner-3.png",
            altText: "Partner 3"
          },
          {
            imageUrl: "Partner-4.png",
            altText: "Partner 4"
          },
          {
            imageUrl: "Partner-5.png",
            altText: "Partner 5"
          },
          {
            imageUrl: "Partner-6.png",
            altText: "Partner 5"
          },
          {
            imageUrl: "Partner-7.png",
            altText: "Partner 5"
          },
          {
            imageUrl: "Partner-8.png",
            altText: "Partner 5"
          },{
            imageUrl: "Partner-9.png",
            altText: "Partner 5"
          },{
            imageUrl: "Partner-10.png",
            altText: "Partner 5"
          },{
            imageUrl: "Partner-11.png",
            altText: "Partner 5"
          }
          ,{
            imageUrl: "Partner-12.png",
            altText: "Partner 5"
          },{
            imageUrl: "Partner-13.png",
            altText: "Partner 5"
          },{
            imageUrl: "Partner-14.png",
            altText: "Partner 5"
          }
          ,{
            imageUrl: "Partner-15.png",
            altText: "Partner 5"
          }
        ],
         
        StatData: [
          {
            imageUrl: "stats1.svg",
            altText: "Stats 1",
            title: "Discover Advanced Technologies in Techhub"
          },
          {
            imageUrl: "stats2.svg",
            altText: "Stats 2",
            title: "Master in-demand skills in Skillhub"
          },
          {
            imageUrl: "stats3.svg",
            altText: "Stats 3",
            title: "Lucrative Affiliate Opportunity"
          },
          {
            imageUrl: "stats4.svg",
            altText: "Stats 4",
            title: "Industry Experts Got Your Back"
          }
        ],
        StatNewData: [
          {
            imageUrl: "stats5.svg",
            altText: "Stats 5",
            title: "Gain Lifetime Access to Courses and Certifications"
          },
          {
            imageUrl: "stats6.svg",
            altText: "Stats 6",
            title: "Showcase Your Certification on Our Website"
          },
          {
            imageUrl: "stats7.svg",
            altText: "Stats 7",
            title: "Receive 24/7 Support for Your Inquiries"
          }
        ]
      };
    },
    mounted() {
      this.getData();      
    },
    methods: {
      toggleSuggestions() {
      this.showSuggestionsList = !this.showSuggestionsList;
    },
      async getData(){
        let response = await ApiClass.getRequest("landing-data/get");
        
        if(response.data.status_code == 1){  
          this.all_Data =  response.data.data;
          // this.setActiveTab(0)
        }       

    },
      toggleTab(index) {
      this.activeTab = index; // Set clicked tab as active
    },
    },
  };
</script>


<style scoped>
  .comm_padding {
    padding: 80px 0;
  }

.text-main{
  color: var(--active-bg)
}
  .bg-overlay {
    /* background-image: url('../assets/images/new-home/bg-banner.webp'); */
    background-repeat: no-repeat;
    background-position: 100%;
    opacity: .1;
  }

  .partner-img {
    aspect-ratio: 1/2;
    object-fit: cover;
    height: 100px;
  }

  .stat_title {
    font-size: 16px;
    line-height: 28px;
    font-style: normal;
  }

  .bg__new {
    background-color: #EDF4F9;
  }

  .main-box {
    background-color: #FFFFFF;
    padding: 31px 30px 33px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(51, 51, 51, 0.15);
    max-width: 270px;
    width: 100%;
  }

  .outter-wrap {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .box-img {
    background-color: #F5F7FD;
    max-width: 50px
  }

  .play-Icon .box-img {
    background-color: #FBF2F1;
  }

  .layer-icon .box-img {
    background-color: #E8F8EC;
  }

  .comment-icon .box-img {
    background-color: #F5EEDD;
  }




  /* feedback */

  .FeedbackCard {
    border-radius: 24px;
    gap: 22px;
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.50);
    /* box-shadow: 0px 4px 20px 0px rgb(167 167 167 / 50%);
     */
  }

  .FeedbackCard .topCard {
    gap: 10px;
  }

  .FeedbackCard .courserImg {
    border-radius: 12px;
  }

  .feedbackBd h4 {
    line-height: 32px;
    letter-spacing: 0.048px;
  }

  .feedbackBd p {
    line-height: 24px;
  }

  .p-32 {
    padding: 32px;
  }

  .studentComment p {
    color: #363A3D;
    line-height: 24px;
  }

  .New_text {
    color: #5272ff;
  }

  .box_main {
    background-color: #F6F3ED;
  }

  .box_main_2 {
    background-color: #EEF0F4;
  }

  .bg__icon {
    width: 80px;
    height: 80px;
    background: var(--active-bg);
    border: 3px solid #fff;
    transform: rotate(50deg);
  }

  .bg__icon img {
    transform: rotate(-50deg);
  }

  .new_boxx:not(:first-child)+.new_boxx::after {
    content: '';
    background: url('../assets/images/new-home/working-arrow.svg') no-repeat;
    position: absolute;
    width: 100%;
    height: 50px;
    top: 15px;
    left: -27px;
  }

  .course__work {
    background: url(../assets/images/new-home/working.jpg) no-repeat;
    background-size: cover;
    z-index: 1;
  }

  .course__work::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #002935;
    opacity: .87;
    z-index: -1;
  }

  .left-content h1{
    font-size: 64px;
    line-height: 72px;
  }

  .search_bar{
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 10px;
    padding: 40px;
    /* height: 600px; */
  }
  .search_bar .tabs-class{
    cursor: pointer;
  }
  .search_bar .active{
      color: #5272ff;
      border-bottom: 2px solid #5272ff;
  }

  @media screen and (max-width:767px) {
    .search_bar{
    padding: 20px;
  }


    .comm_padding {
      padding: 30px 8px;
    }

    .main-box {
      max-width: 100%;
    }

    br {
      display: none;
    }

    .courses_btn div {
      display: flex;
      justify-content: center;

    }

    .main-instru-img {
      position: unset !important;
    }

    .instructor-card {
      width: auto !important;
    }

    .blog-content {
      padding: 12px;
    }

   
    :is(.bg__icon , .bg__icon img) {
    transform: rotate(0deg);
  }
  h2{
    font-size: 28px !important;
  }
  }

  @media screen and (max-width:992px){
    .left-content h1{
        font-size: 34px;
        line-height: 42px;
    }
  }

  @media screen and (max-width:1199px){
    .new_boxx:not(:first-child)+.new_boxx::after {
      display: none;
    }

    .comm_padding{
      padding: 40px 0;
    }

  }

  .module_section{
    padding-top: 10rem;
  }

  /* .module_card{
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  } */
  #preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Customize as needed */
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 18px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--active-bg); /* Blue */
  border-radius: 50%;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 2s linear infinite;
}

.preloader-logo {
  width: 150px; /* Adjust size as needed */
  /* animation: spin 2s linear infinite; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hero_section {
  opacity: 0;
  transition: opacity .3s ease-in;
}

.hero_section.loaded {
  opacity: 1;
}
.search-bar-list{
  position: absolute;
  z-index: 999;
  background-color: white;
  width: 100%;
  top: 58px;
  padding: 1rem .6rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  /* display: none; */
}
.search-bar-list ul li{
list-style: none;
margin-bottom: .6rem;
padding: .3rem .6rem;
color: #000;

}
.search-bar-list ul li:hover{
  background-color: #fafafc;
  border-radius: 5px;
}
.search-icon {
  display: inline-block;
  width: 16px;
  height: 16px; 
  background-image: url('../assets/images/new-home/search-bar.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: .7rem;
}
.pl-0{
padding-left: 0 !important;
}
</style>