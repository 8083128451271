<template>
    <div>
        <section class="hero_section position-relative comm_padding pb-5 mt-3 mt-md-0">
            <div
                class="comm_padding module_section comm_padding bg__new position-absolute top-0 start-0 end-0 bottom-0">
            </div>
            <div class="container z-3 position-relative">
                <div class="row align-items-center justify-content-between py-5">
                    <div class="col-lg-6">
                        <div class="left-content z-3 mt-4">
                            <h1 class="fw-bold lh-base mb-2 text-center text-md-start">Upskill and Reskill with
                                SkillHub!</h1>
                            <p class=" text-center text-md-start">At evolveair, we believe in the power of continuous
                                learning. That's why we offer a comprehensive
                                library of courses designed to equip you with the skills you need to excel in career.
                                Whether you're a
                                seasoned professional looking to expand your skillset or a complete beginner eager to
                                launch your
                                career, we have something for everyone..</p>
                            <div class="video-modal mb-3 d-flex gap-3">
                                <div
                                    class="w-100 d-flex justify-content-center d-md-inline-block justify-content-md-left mt-4">
                                    <a href="https://evolveair.org/userpanel/">
                                        <GlobalButton title="Learn More" clickUrl="skill-hub" />
                                    </a>
                                </div>
                                <!-- <span><img src="../assets/icons/play.svg" alt="" width="45"/> Watch Video</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="img_new mt-5">
                            <img src="../assets/images/skillhub/hub.png" alt="" class="w-100 rounded">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="joinUs comm_padding module_section comm_padding">
            <div class="container">
                <div class="row align-items-center  gap-0 row-gap-4">
                    <div class="col-md-5 col-lg-5">
                        <div class="imgWrap">
                            <img src="../assets/images/skillhub/experience.png" alt="" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-7">
                        <div class="text-group text-center text-sm-start">
                            <div class="joinus-text section_title d-flex flex-column gap-3 mb-3">
                                <h1 class="fw-bold lh-base mb-2 ">A Well-Rounded Educational Experience</h1>
                                <p class=" ">Our SkillHub courses are meticulously crafted to provide a thorough and
                                    versatile learning
                                    experience. We go beyond just teaching the basics; we equip you with real-world
                                    skills and
                                    knowledge that you can apply immediately in your professional life.
                                </p>
                            </div>
                            <div class="d-flex justify-content-center justify-content-sm-start">
                                <a href="https://evolveair.org/userpanel/">
                                    <GlobalButton title="Get Started" clickUrl="skill-hub" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container mt-4">
                <h1 class="fw-bold lh-base mb-2 text-center ">Flexible Learning Packages</h1>
                <p class=" text-center ">We understand that everyone learns differently and has unique learning needs.
                    <br> That's why
                    we offer a variety of learning packages to choose from:
                </p>

                <div class="pricing-table mt-5">
                    <div class="pricing-card">
                        <h3 class="pricing-card-header">Basic</h3>
                        <div class="price">&#8377; 1500</div>
                        <ul>
                            <li> Access 1 course out of all available courses under SkillHub</li>

                            <li>Online Certification</li>
                            <li>Add-ons: Rs. 1200/ Course</li>
                        </ul>
                        <div class="buy-pack">
                            <a href="https://evolveair.org/userpanel/" class="order-btn">Buy Package</a>
                        </div>
                    </div>

                    <div class="pricing-card">
                        <h3 class="pricing-card-header">Elite</h3>
                        <div class="price">&#8377; 5000</div>

                        <ul>
                            <li>Access 6 courses out of all available courses under SkillHub</li>
                            <li>Online Certification</li>
                            <li>Offline Certification</li>
                            <li>Add-ons: Rs.1000/Course</li>
                        </ul>
                        <div class="buy-pack">
                            <a href="https://evolveair.org/userpanel/" class="order-btn">Buy Package</a>
                        </div>
                    </div>

                    <div class="pricing-card">
                        <h3 class="pricing-card-header">Premium</h3>
                        <div class="price">&#8377; 8000</div>
                        <ul>
                            <li>Access 12 courses out of all available courses under SkillHub</li>
                            <li>Online Certification</li>
                            <li>Offline Certification</li>
                            <li>Blings</li>
                            <li>Add-ons: Rs. 800/ Course</li>
                        </ul>
                        <div class="buy-pack">
                            <a href="https://evolveair.org/userpanel/" class="order-btn">Buy Package</a>
                        </div>
                    </div>

                    <div class="pricing-card">
                        <h3 class="pricing-card-header">Ultra</h3>
                        <div class="price">&#8377; 24000</div>

                        <ul>
                            <li>Get access to every course available under SkillHub</li>

                            <li>Online Certification</li>
                            <li>Offline Certification</li>
                            <li>Super Blings</li>
                        </ul>
                        <div class="buy-pack">
                            <a href="https://evolveair.org/userpanel/" class="order-btn">Buy Package</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="joinUs comm_padding module_section comm_padding bg__new mt-6">
            <div class="container">
                <div class="row align-items-center  gap-0 row-gap-4 justify-content-between">
                    <div class="col-md-5 col-lg-5">
                        <div class="imgWrap">
                            <img src="../assets/images/skillhub/vec_sep.png" alt="" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-7">
                        <div class="text-group text-center text-sm-start">
                            <div class="joinus-text section_title d-flex flex-column gap-3 mb-3">
                                <div class="d-flex align-items-center">
                                    <div class="">
                                        <img src="../assets/images/new-home/main-cap.svg" alt="">
                                    </div>
                                    <h1 class="fw-bold lh-base ms-2"> Blings</h1>
                                </div>
                                <p class="">Blings are exclusive perks designed to elevate your skills and career,
                                    available to our
                                    esteemed Premium and Ultra users. These exciting offerings can be virtual or
                                    physical,
                                    adding an extra layer of engagement to your learning journey.

                                </p>
                            </div>
                            <!-- <div class="d-flex justify-content-center justify-content-sm-start">
                <GlobalButton title="Get Started" clickUrl="skill-hub" />
              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <div class="container py-y my-5 ">
            <section id="advertisers" class="advertisers-service-sec pt-5 pb-5 mt-5">
                <div class="container">
                    <div class="row">
                        <div class="section-header text-center mb-4">
                            <h2 class="fw-bold fs-1">
                                Solidify your <span class="b-class-secondary">learning and validate </span>your skills:
                            </h2>
                            <!-- <p class="sec-icon"><i class="fa-solid fa-gear"></i></p> -->
                            <p>We provide a comprehensive learning experience that goes beyond just lectures. Our
                                courses include</p>
                        </div>
                    </div>
                    <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center mb-5">
                        <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                            <div class="service-card">
                                <div class="icon-wrapper">
                                    <i class="fa-solid fa-globe"></i>
                                </div>
                                <h3 class="text-center">areas for
                                    improvement</h3>
                                <p class="text-center">
                                    Engaging quiz assessments
                                    to test your understanding
                                    and identify areas for
                                    improvement
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                            <div class="service-card mtt-4">
                                <div class="icon-wrapper">
                                    <i class="fa-solid fa-arrows-down-to-people"></i>
                                </div>
                                <h3 class="text-center"> Unique reading materials</h3>
                                <p class="text-center">
                                    Unique reading materials
                                    curated by industry experts
                                    to provide in-depth
                                    knowledge and insights
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
                            <div class="service-card">
                                <div class="icon-wrapper">
                                    <i class="fa-solid fa-wallet"></i>
                                </div>
                                <h3 class="text-center">Valuable certifications</h3>
                                <p class="text-center">
                                    Valuable certifications upon
                                    course completion to
                                    showcase your newly
                                    acquired skills and boost
                                    your resume
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section>
            <div class="container py-y my-5">
                <div class="bg-main py-5 start-now mt-6">
                    <h1 class="fw-bold lh-base mb-2 text-center text-white ">Start your learning journey with SkillHub
                        today!
                    </h1>
                    <p class=" text-center text-white ">xplore our course catalog, choose your learning package, and
                        unlock a world of
                        possibilities.
                    </p>
                    <div class="w-100 d-flex justify-content-center mt-5 ">
                        <a href="https://evolveair.org/userpanel/register">
                            <GlobalButton title="Start Now" />
                        </a>
                    </div>
                </div>

            </div>
        </section>
        <section class="course__view">
            <div class="container">
                <h1 class="fw-bold lh-base mb-2 text-center py-4">Courses List</h1>
                <div class="row gap-0 row-gap-4 justify-content-between mb-4 align-items-center">
                    <div class="col-lg-6">
                        <p class="mb-0 show-result fs-7 fw-normal">Showing all {{ all_Data.length ?? 0 }} results</p>
                    </div>
                    <div class="col-lg-3">
                        <!-- <div class="row gap-0 row-gap-4 justify-content-end">
            <div class="col-lg-5 pe-md-0">
                <div class="searchBar">
                  <input type="search" class="form-control" placeholder="Serch Courses">
                </div>
              </div>
              <div class="col-lg-5">
                  <select class="form-select cst-form bg-white h-100">
                    <option selected>Default Sorting</option>
                    <option value="1">Newest</option>
                    <option value="2">Oldest</option>
                    <option value="3">Most popular</option>
                    <option value="3">Most purchased</option>
                </select>
              </div>
          </div> -->
                        <!-- <div class="searchBar d-flex align-items-center gap-2">
            <input type="search" class="form-control" placeholder="Serch Courses">
          </div> -->

                    </div>
                </div>
                <div class="row mb-5 gap-0 row-gap-4">
                    <div class="col-lg-3" v-for="(value, index) in all_Data" :key="index">

                        <CourseCard :title="value.title" :price="parseFloat(value.price)" :og_image="value.og_image" />
                    </div>
                    <!-- <div class="col-lg-3">
              <CourseCard title="Time Management Mastery: Do More, Stress Less" price="458" />
          </div>
          <div class="col-lg-3">
              <CourseCard title="Angular – The Complete Guide Master Courses (2020 Edition)" price="5879" />
          </div>
          <div class="col-lg-3">
              <CourseCard title="Become a Product Manager | Learn the Skills & Get the Job" price="689" />
          </div> -->
                </div>
                <!-- <div class="row">
        <div class="cst-pagination">
          <v-pagination
            v-model="page"
            :pages="5"
            :range-size="1"
            active-color="#5272ff"
            @update:modelValue="updateHandler"
          />
        </div>
      </div> -->
            </div>
        </section>
    </div>
</template>

<script>
// import Vue3autocounter from 'vue3-autocounter';
import CourseCard from '../components/CourseCard.vue';
// import VPagination from "@hennge/vue3-pagination";
import GlobalButton from "@/components/GlobalButton.vue";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ApiClass from '@/api/api';
import appConfig from "@/app.config";
export default {
    name: "HomeView",
    components: {
        CourseCard,
        GlobalButton,
        // VPagination
    },
    page: {
    title: "Skillhub",
    meta: [{ name: "description", content: appConfig.description}],
  },
    data() {
        return {
            all_Data: [],
        };
    },

    mounted() {

        this.getData();
    },
    methods: {
        async getData() {
            let response = await ApiClass.getRequest("skill-data/get");

            if (response.data.status_code == 1) {
                this.all_Data = response.data.data;
                // this.setActiveTab(0)
            }

        },
        toggleTab(index) {
            this.activeTab = index; // Set clicked tab as active
        },
    },

};
</script>

<style scoped>
.course__view {
    padding: 80px 0;
}

.show-result {
    font-size: 14px;
}

.course__view .cst-form {
    font-size: 13px;
    border: 1px solid #DDD;
    background: url(../assets/icons/dots.svg) no-repeat;
    background-position: 95%;
}

.cst-pagination ul {
    justify-content: center;
}

.searchBar input {
    font-size: 14px;
}

.pricing-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: min(1600px, 100%);
    margin: auto;
}

.pricing-card {
    flex: 1;
    max-width: 360px;
    height: 450px;
    background-color: #fff;
    margin: 20px 10px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    color: #2d2d2d;
    transition: .3s linear;
    padding: 0 .6rem;
    position: relative !important;
    border-radius: 8px;

}

.pricing-card-header {
    background-color: #718bff;
    display: inline-block;
    color: #fff;
    padding: 12px 30px;
    border-radius: 0 0 120px 12px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    transition: .4s linear;
    margin-bottom: 1rem;
}

.pricing-card:hover .pricing-card-header {
    box-shadow: 0 0 0 26em #718bff;
}

.price {
    font-size: 28px;
    color: #718bff;
    margin: 10px 0;
    transition: .2s linear;
    font-weight: 600;
}

.buy-pack {
    position: absolute;
    bottom: 24px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
}

.price sup,
.price span {
    font-size: 18px;
    font-weight: 700;
}

.pricing-card:hover,
.pricing-card:hover .price {
    color: #fff;
}

.pricing-card:hover .order-btn {
    color: #718bff;
    background-color: #fff;
}

.pricing-card li {
    font-size: 16px;
    padding: 8px 0;
    text-transform: capitalize;
    text-align: left;
}

.order-btn {
    display: inline-block;
    margin-top: 20px;
    border: 1px solid #718bff;
    color: #718bff;
    padding: 10px 32px;
    border-radius: 8px;
    text-transform: capitalize;
    font-weight: 500;
    transition: .3s linear;
}

.order-btn:hover {
    background-color: #718bff;
    color: #fff;
}

.start-now {
    border-radius: 12px;
}

.mt-6 {
    margin-top: 6rem;
}

@media screen and (max-width:1100px) {
    .pricing-card {
        flex: 50%;
    }
}

/* .course__view .form-select {
    background: url(../assets/icons/dots.svg);
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
</style>
