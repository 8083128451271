<template>
  <div>
    <Header />
    <router-view />

  
  <Footer />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      const value = title ? `${title} | ${appConfig.title}` : appConfig.title;
      console.log({value});
      
      return value
    },
  },
  components: {
    Header,
    Footer,
  },


};
</script>

<style>
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat' !important;
  background-color: var(--main-bg) !important;
}


@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

:root {
  --main-bg: #fafafc;
  --black: #101724;
  --white: #fff;
  --button-bg: #453fe1;
  --blue: #2f29dd;
  --grey: #808080;
  --active-bg: #5271FF;
  --light-white: #f1f1fb;
  --bg-2: #ebeff8;
}


::selection{
  background-color: var(--active-bg) ;
  color: #FFF;
}
.mtt-4{
  margin-top: 4rem;
}
@media all and (min-width: 992px) and (max-width: 1199px) {
  .category-info h3 br {
    display: none;
  } 
  .mtt-4{
  margin-top: 1px !important;
}  
}
@media all and (min-width: 700px) and (max-width: 992px) { 
  .mtt-4{
  margin-top: 1px !important;
}  
}
@media all and (min-width: 320px) and (max-width: 767px) {
  .mtt-4{
  margin-top: 1px !important;
}
  .category-info h3 br {
    display: none;
  }

  .heading-box p br {
    display: none;
  }
  .pr-52{
  padding-right: 100px !important;
}
.pl-48{
  padding-left: 100px !important;
}
.p-10{
  padding: 20px !important;
  border: 12px solid white;
}

}
a{
  text-decoration: none !important;
}
body .form-control:focus,
body .form-select:focus{
  border-color: var(--active-bg) ;
  box-shadow: none !important;
}

:is(.carousel-container .carousel__prev , .carousel-container .carousel__next) {
    background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
    width: 44px;
    height: 44px;
    border-radius: 1000px;
} 
.carousel-container .carousel__prev {
    left: -78px;
}

.carousel-container  .carousel__next {
    right: -78px;
}

.carousel-container .carousel__icon {
  fill:#FFF;
}

.carousel-container .carousel__pagination button{
  margin-right: 12px;
}

.carousel-container .carousel__pagination-button:hover::after,
.carousel-container .carousel__pagination-button--active::after,
.global__Button  {
  background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
}

.carousel-container .carousel__pagination-button::after {
    display: block;
    width: 10px;
    height: 10px  ;
    content: '';
    border-radius: 1000px;
    background-color: #CFD3D6;    
}

.dropdown-item.active, .dropdown-item:active {
    background-color: var(--active-bg) !important;
}

@media screen and (max-width:1200px){
  .carousel-container .carousel__prev , .carousel-container .carousel__next{
    display: none;
  }
}
.fs-7{
  font-size: 14px;
}
.feedback .carousel__slide{
    margin-right: 15px;
}

.bg-main{
  background-color: #718bff;
}
.bg__new {
    background-color: #EDF4F9;
  }
  .comm_padding {
    padding: 80px 0;
  }
.sec-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.sec-icon::before {
  content: "";
  position: absolute;
  height: 1px;
  left: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #333333;
  width: 50px;
}

.sec-icon::after {
  content: "";
  position: absolute;
  height: 1px;
  right: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #333;
  width: 50px;
}


.advertisers-service-sec span {
  color: #5271FF;
}

.advertisers-service-sec .col {
  padding: 0 1em 1em 1em;
  text-align: center;
}

.advertisers-service-sec .service-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 8px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#728cff, #5271FF);
  position: absolute;
  left: 0%;
  top: -99%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec p {
  color: #000;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {
  background-color: #ffffff;
  color: #5271FF;
}

.service-card .icon-wrapper i{
  font-size: 40px !important;

}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: #728cff;
}

.advertisers-service-sec .service-card:hover h3 {
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover p {
  color: #f0f0f0;
}

.text-blue{
  color: #5271FF;

  
}



</style>
