<template>
    <section class="contact_us">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="contact_inner">
                        <div class="row ">
                            <div class="col-ms-12 col-md-6 p-5">
                                <div class="contact_form_inner">
                                    <div class="contact_field">
                                        <h3>Contact Us</h3>
                                        <p>Feel Free to contact us any time. We will get back to you as soon as we can!.
                                        </p>
                                        <input type="text" class="form-control form-group" placeholder="Name" />
                                        <input type="text" class="form-control form-group my-3" placeholder="Email" />
                                        <textarea class="form-control form-group" placeholder="Message"
                                            rows="5"></textarea>
                                        <button class="contact_form_submit">Send</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-2">
                                <div class="right_conatct_social_icon d-flex align-items-end">
                                   <div class="socil_item_inner d-flex">
                                      <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                   </div>
                                </div>
                            </div> -->
                            <div class=" col-ms-12 col-md-6  d-flex justify-content-end align-items-center">
                                <div class="contact_info_sec w-100">
                                    <h4>Contact Info</h4>
                                    <!-- <div class="d-flex info_single align-items-center">
                                <i class="fas fa-headset"></i>
                                <span><a href="tel:+917324999909" class="textNew text-white text-decoration-none">+91 732 499 9909</a></span>
                            </div> -->
                                    <div class="d-flex info_single align-items-center">
                                        <i class="fas fa-envelope-open-text"></i>
                                        <span><a href="mailto:echo@evolveair.org"
                                                class="text-decoration-none text-white textNew">echo@evolveair.org</a></span>
                                    </div>
                                    <div class="d-flex info_single align-items-center">
                                        <i class="fas fa-map-marked-alt"></i>
                                        <span>Pune, Maharastra</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="map_sec">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="map_inner">
                        <h4 class="fw-semibold">Find Us on Google Map</h4>
                        <p>Home is where your wifi connects automatically. Here’s where ours does. Give us a call—or
                            better yet—drop into <br> our headquarters to say hi in person.</p>
                        <div class="map_bind">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.50936749486!2d73.71589107595781!3d18.596145566859803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb8d68a956a5%3A0xa4de66b23b841e26!2sVirtuCrop%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1711517941395!5m2!1sen!2sin"
                                width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""
                                aria-hidden="false" tabindex="0" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import appConfig from "@/app.config";
export default {
    name: "ContactView",
    data() {
        return {

        };
    },
    page: {
        title: "Contact",
        meta: [{ name: "description", content: appConfig.description }],
    },
    mounted() {

    },
    methods: {

    },
};

</script>

<style scoped>
.contact_us {
    background-color: #f1f1f1;
    padding: 120px 0px;
}

.contact_inner {
    background-color: #fff;
    position: relative;
    box-shadow: 20px 22px 44px #cccc;
    border-radius: 25px;
}

.contact_field {
    /* padding: 60px 340px 90px 100px; */
}

.right_conatct_social_icon {
    height: 100%;
}

.contact_field h3 {
    color: #000;
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px
}

.contact_field p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 35px;
}

.contact_field .form-control {
    border-radius: 8px;
    border: 1px solid #ccc;
}

.contact_field .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--active-bg);
}

.contact_field .form-control::placeholder {
    font-size: 13px;
    letter-spacing: 1px;
}

.contact_info_sec {
    /* position: absolute; */
    background-color: var(--active-bg);
    right: 1px;
    top: 18%;
    height: 340px;
    width: 340px;
    padding: 40px;
    border-radius: 25px 0 0 25px;
    color: #fff;
}

.contact_info_sec h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
}

.info_single {
    margin: 30px 0px;
}

.info_single i {
    margin-right: 15px;
}

.info_single span {
    font-size: 14px;
    letter-spacing: 1px;
}

button.contact_form_submit {
    background: linear-gradient(180deg, #5272ffb9 0%, #5272ffdc 100%);
    border: none;
    color: #fff;
    padding: 10px 15px;
    width: 100%;
    margin-top: 25px;
    border-radius: 35px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 2px;
    height: 45px;
}

.socil_item_inner li {
    list-style: none;
}

.socil_item_inner li a {
    color: #fff;
    margin: 0px 15px;
    font-size: 14px;
}

.socil_item_inner {
    padding-bottom: 10px;
}

.map_sec {
    padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
    color: #000;
    text-align: center
}

.map_inner p {
    font-size: 13px;
}

.map_bind {
    margin-top: 50px;
    border-radius: 30px;
    overflow: hidden;
}
</style>
