<template>
  <div>
    <footer>
      <div class="container">
        <div class="row gap-0 row-gap-4">
          <div class="col-md-6 col-lg-4">
            <div class="footer-widget">
              <div class="footer-logo mb-4">
                <a href="/">
                  <img src="../assets/images/evolve-logo.png" alt="" class="img-fluid" width="140">
                </a>
              </div>
              <div class="contactUs">
                <h4 class="mb-15 contact-title fs-4 fst-normal fw-semibold text-white">Contact Us</h4>
                <div class="textNew mb-15">Email : <a href="mailto:echo@evolveair.org" class="text-decoration-none hoverText textNew">echo@evolveair.org</a></div>
           
                <p class="textNew mb-15">Pune, Maharastra</p>
                <ul class="social-icon p-0 m-0 d-flex gap-4 align-items-center">
                  <li class="list-group-item">
                    <a target="_blank" href="https://www.linkedin.com/showcase/evolveair.org/">
                    <i class="fa-brands text-white fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="https://www.instagram.com/evolveair.official/">
                    <i class="fa-brands text-white fa-instagram"></i>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="https://x.com/evolveair_org">
                    <i class="fa-brands text-white fa-x-twitter"></i>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="https://www.youtube.com/@evolveair.official">
                    <i class="fa-brands text-white fa-youtube"></i>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="https://medium.com/@evolveair.org">
                    <i class="fa-brands text-white fa-medium"></i>
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a target="_blank" href="https://whatsapp.com/channel/0029VaAbo7y77qVWFyCpFt24">
                    <i class="fa-brands text-white fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            </div>
            <div class="col-md-6 col-lg-2">
              <div class="footer-widget">
                <div class="contactUs">
                  <h4 class="contact-title fs-4 fst-normal fw-semibold text-white mb-31">Explore</h4>
                  <ul class="p-0 m-0 d-flex gap-4 flex-column justify-content-center">
                    <li class="list-group-item">
                      <router-link to="/about" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        About
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/blogs" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        Blog
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/contact" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        contact
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/terms-conditions" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        Terms &#38; Conditions
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/privacy-policy" target="_blank" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        Privacy Policy
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/delivery-policy"  target="_blank" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        <!-- Shipping &#38; Delivery -->
                         Delivery Policy
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/refund-policy"  target="_blank" class="menuText hoverText text-decoration-none text-capitalize mb-15">
                        <!-- Shipping &#38; Delivery -->
                      Refund Policy
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2">
              <div class="footer-widget">
                <div class="contactUs">
                  <h4 class="contact-title fs-4 fst-normal fw-semibold text-white mb-31">Category</h4>
                  <ul class="social-icon p-0 m-0 d-flex flex-column gap-4 justify-content-center">
                    <li class="list-group-item">
                      <router-link to="/skill-hub" class="menuText hoverText text-decoration-none mb-15">
                        Skill hub
                      </router-link>
                    </li>
                    <!-- <li class="list-group-item">
                      <router-link to="/tech-hub" class="menuText hoverText text-decoration-none mb-15">
                        Tech hub
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/ascep" class="menuText hoverText text-decoration-none mb-15">
                        ASCEP
                      </router-link>
                    </li>
                    <li class="list-group-item">
                      <router-link to="/affiliate" class="menuText hoverText text-decoration-none mb-15">
                        Affiliate
                      </router-link>
                    </li>
                     -->
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
             <div class="footer-widget">
                <div class="contactUs">
                  <h4 class="contact-title fs-4 fst-normal fw-semibold text-white">Subscribe</h4>
                  <p class="menuText text-decoration-none mb-21">Stay Informed: Subscribe for Updates, News, and Exclusive Offers Today!</p>
                  <div class="newstletter ">
                    <div class="mb-31 pb-1">
                      <input type="text" class="form-control p-3 border-0" placeholder="Email here">
                    </div>
                    <GlobalButton title="Subscribe" />
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="bottom_copyright row py-5">
          <div class="col-lg-6">
            <div class="text-white fs-7">
              © {{ currentYear }} <router-link to="/" class="text-white">Evolveair</router-link>. All Rights Reserved.
            </div>
          </div>
          <div class="col-lg-6 text-end">
              <div class="text-white fs-7">
                Project by: <a target="_blank" href="https://virtucrop.com/"><img src="../assets/images/new-home/virtucrop.jpg" alt="" width="100" class="ps-1"></a>
              </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import GlobalButton from "@/components/GlobalButton.vue";


export default {
  name: "FooterComponent",
  components:{
    GlobalButton
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    setInterval(() => {
      this.currentYear = new Date().getFullYear();
    }, 1000);


  }
};
</script>

<style scoped>
  footer{
    padding: 100px 0 0;
    background:  #21212F;
  }

  .footer-logo{
    filter: invert(100%) sepia(12%) saturate(4%) hue-rotate(119deg) brightness(103%) contrast(100%);
  }

footer .contact-title{
  line-height: 32px;
  letter-spacing: 0.048px;
}

.textNew{
  color: #E7E9EB;
  line-height: 24px;
}

.footer-widget .hoverText:hover {
    color: #5271ff;
}

.menuText {
  color: #CFD3D6;
  line-height: 24px;
}

.mb-15{
  margin-bottom: 15px;
}

.mb-7{
  margin-bottom: 7px;
}

.mb-31{
  margin-bottom: 31px;
}

.mb-21{
  margin-bottom: 21px;
}


@media screen and (max-width:767px) {
  .mb-31{
      margin-bottom: 11px;
  }
}


</style>
