<template>
  <section class="hero_section position-relative comm_padding pb-5 mt-3 mt-md-0">
    <div class="bg-overlay position-absolute top-0 start-0 end-0 bottom-0"></div>
    <div class="container z-3 position-relative">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="left-content z-3">
            <h1 class="fw-bold lh-base mb-2 text-center text-md-start">Where Knowledge Meets Evolution</h1>
            <p class=" text-center text-md-start">Welcome to evolveair your go-to destination for online education. With
              expert instructors, interactive learning, and a wide range of topics, we're here to empower you on your
              journey of growth and development. Join our community and start evolving with evolveair today.</p>
            <div class="video-modal mb-3 d-flex gap-3">
              <div class="w-100 d-flex justify-content-center d-md-inline-block justify-content-md-left">
                <router-link to="contact">
                  <GlobalButton title="Contact us" />
                </router-link>
              </div>
              <!-- <span><img src="../assets/icons/play.svg" alt="" width="45"/> Watch Video</span> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img_new mt-5">
            <img src="../assets/images/about/about-hero.png" alt="" class="w-100 rounded">
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <div class="hero">
      <h2 class="main-heading"><strong>Improving Lives</strong> <br>Improving Lives</h2>
    </div> -->
  <div class="container py-y my-5">
    <div class="section-title">
      <h6 class="main-color text-center mt-5">START TO SUCCESS</h6>
      <h1 class="text-center my-4">From Learning to Leading: Transform Your Future with evolveair</h1>
      <div>
        <p class="text-center">Experience the journey from learning to leading with evolveair. Our expert-led courses
          and interactive platform empower you to transform your future. Whether you're seeking professional growth or
          personal development, we provide the tools and support to help you succeed. Start evolving with us today.</p>
      </div>
    </div>
    <div class="row text-center mt-3 py-5">

      <div v-for="(item, index) in CounterData" :key="index" class="p-4 col-lg-3 col-md-6">
        <img :src="require('../assets/images/about/' + item.img)" alt="" class="counter-img mb-3 rounded" width="150" />
        <!-- <img src="../assets/images/cardone.png" alt=""> -->
        <p class="counter-number main-color"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='item.count'
            :duration='3' separator=',' :autoinit='true' />+</p>
        <p class="text-center mt-3 px-3 counter-text">{{ item.text }}</p>
      </div>
    </div>

    <div class="text-center mt-5 p-md-5 rounded ">
      <div class="section-title mb-5">
        <h1 class="text-center">What Make Us Spcecial?</h1>
        <div>
          <p class="text-center">Expertise, Innovation, Community: evolveair's Unique Blend.</p>
        </div>
      </div>
      <div class="d-md-flex align-items-center justify-content-between w-100">
        <div class="row mt-2 gap-0 row-gap-4">
          <div class="col-md-12 col-lg-4">
            <div class="buil-step h-100">
              <div>
                <img src="../assets/images/about/p-1.svg" alt="" class="mb-2">
                <p class=""><b>Build</b></p>
              </div>
              <p class="text-center mt-4">At the heart of EvolveAir's ethos lies a commitment to building. This
                principle encompasses more than just physical infrastructure; it extends to fostering a culture of
                innovation and advancement. EvolveAir invests in cutting-edge technologies, from state-of-the-art
                aircraft designs to revolutionary maintenance processes.</p>
            </div>
          </div>
          <div class="col-md-12 col-lg-4">
            <div class="buil-step h-100 step-2">
              <div>
                <img src="../assets/images/about/p-4.svg" alt="" class="mb-2">
                <p class=""><b>Learn</b></p>
              </div>
              <p class="text-center mt-4">In the fast-paced world of aviation, learning is not just encouraged—it's
                essential. EvolveAir embraces a culture of continuous learning, recognizing that knowledge is the key to
                adaptation and growth. Through rigorous training programs, ongoing research initiatives, and a
                commitment to collaboration, EvolveAir cultivates an environment where curiosity thrives.</p>
            </div>
          </div>
          <div class="col-md-12 col-lg-4">
            <div class="buil-step h-100 ">
              <div>
                <img src="../assets/images/about/p-3.svg" alt="" class="mb-2">
                <p class=""><b>Earn</b></p>
              </div>
              <p class="text-center mt-4">While innovation and learning are fundamental, sustainability is equally
                crucial. EvolveAir understands that profitability is not just about financial gains; it's about creating
                value for all stakeholders while safeguarding the planet for future generations. By prioritizing
                environmental responsibility,EvolveAir ensures that its success is both meaningful and enduring.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="place-grow-area py-5 mt-5">
      <h1 class="text-center">A Great Place to Grow</h1>
      <div>
        <p class="text-center">Where Growth Thrives: evolveair's Exceptional Learning Environment.</p>
      </div>
      <section class="min-h-screen bg-gray-900 text-center py-20 px-8 xl:px-0 flex flex-col justify-center mt-5">
        <div class="grid-offer text-left row">
          <div class="card bg-gray-800 p-10 relative mb-3 mb-lg-0 col-sm-12 col-xl-6">
            <div class="circle">
            </div>
            <div class="relative pr-52">
              <h2 class="capitalize text-black fw-600 fs-4  mb-4">SkillHub</h2>
              <p class="text-gray-400">Skillhub: Learning packages to become a coding ninja, Shakespearean writer, or
                social media slayer. We build future rockstars. #LevelUp</p>
            </div>
            <div class="icon"></div>
          </div>
          <div class="card bg-gray-800 p-10 relative mb-3 mb-lg-0 col-sm-12 col-xl-6">
            <div class="circle">
            </div>
            <div class="relative pl-48">
              <h2 class="capitalize text-black fw-600 fs-4  mb-4">Affiliate Program</h2>
              <p class="text-gray-400">Share your knowledge with the world and get paid with Evolveair Affiliate. Every
                time someone signs up through you, you earn serious cash
              </p>
            </div>
          </div>
          <div class="card bg-gray-800 p-10 relative mb-3 mb-lg-0 col-sm-12 col-xl-6">
            <div class="circle">
            </div>
            <div class="relative pr-52">
              <h2 class="capitalize text-black fw-600 fs-4  mb-4">TechHub</h2>
              <p class="text-gray-400">Techhub ditches basic tutorials. We're your advanced tech dojo, transforming
                coding grasshoppers into full- fledged senseis. Blockchain or deep learning? We got your knowledge
                bombs. #LevelUpYourCode
              </p>
            </div>
          </div>
          <div class="card bg-gray-800 p-10 relative mb-3 mb-lg-0 col-sm-12 col-xl-6">
            <div class="circle">
            </div>
            <div class="relative pl-48">
              <h2 class="capitalize text-black fw-600 fs-4  mb-4">ASCEP</h2>
              <p class="text-gray-400">ASCEP an uniquely designed 90 days skill and career enhancement program
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="pt-5 d-flex align-items-center w-100 justify-content-center">
        <a href="https://evolveair.org/userpanel/register">
          <GlobalButton title="Join our team" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalButton from "@/components/GlobalButton.vue";
import Vue3autocounter from 'vue3-autocounter';
import appConfig from "@/app.config";

export default {
  name: "AboutView",
  page: {
    title: "About",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    GlobalButton,
    'vue3-autocounter': Vue3autocounter
  },
  data() {
    return {
      CounterData: [
        {
          img: 'learner1.jpg',
          count: 1500,
          text: 'Learners enrolled in evolveair courses'
        },
        {
          img: 'exposer2.jpg',
          count: 10,
          text: 'Collaborations with summits and expos'
        },
        {
          //  img:'accerdiation.png',
          img: 'universities.webp',
          count: 55,
          text: 'Accreditation from national and international universities'
        },
        {
          //  img:'teacher-learn.jpg',
          img: 'multinational.png',
          count: 105,
          text: 'Affiliated with multinational companies'
        },
      ]
    };
  },

  mounted() {

  },
  methods: {

  },


};

</script>

<style scoped>
.make-us {
  background-color: #000000;
  /* background-image: url(https://img.freepik.com/free-photo/business-people-blue-background_53876-101889.jpg?w=1380&t=st=1711522783~exp=1711523383~hmac=261fe15b6922bed2bad4468cbd78d3745574732bc5720137f81d9d58890335f6); */
  background-position: center;
  background-size: cover;
  color: #ffffff;
  padding: 1em;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  height: 32vh;
  width: 100%;
  border-radius: 8px;
}

.who-we-are {
  background-image: linear-gradient(rgb(0 0 0 / 11%), rgb(0 0 0 / 93%)), url('../assets/images/about/grow-1.jpg');
}

.what-do {
  background-image: linear-gradient(rgb(0 0 0 / 11%), rgb(0 0 0 / 93%)), url('../assets/images/about/what-we-do.jpg');
}

.our-mission {
  background-image: linear-gradient(rgb(0 0 0 / 11%), rgb(0 0 0 / 93%)), url('../assets/images/about/our-mission.jpg');
}

h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
}

.main-heading strong {
  font-size: 64px;
  font-weight: 700;
}

.main-color {
  color: var(--active-bg);

}

.section-title {
  max-width: 670px;
  margin: 0 auto;
}

.counter-img {
  height: 150px;
  object-fit: contain;
  border-radius: 24px;
  mix-blend-mode: multiply;
  filter: contrast(1);
}

.img_new img {
  mix-blend-mode: multiply;
  filter: contrast(1);
}

.counter-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}

.counter-number {
  font-size: 40px;
  font-weight: 600;

}

.place-grow-area img {
  height: 430px;
  object-fit: cover;
}

@media screen and (orientation:portrait) {
  h1 {
    font-size: 24px;
  }
}


.card {
  position: relative;
}



.card:nth-child(1)::before {
  bottom: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
}

.card:nth-child(2)::before {
  bottom: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
}

.card:nth-child(3)::before {
  top: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
}

.card:nth-child(4)::before {
  top: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
}

.card p {
  transition: 0.8s;
}

.card:hover::before {
  clip-path: circle(110vw at 100% 100%);
}


.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.card:nth-child(1) .circle {
  background: url("https://images.unsplash.com/photo-1587440871875-191322ee64b0?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat 50% 50% / cover;
  bottom: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
}

.card:nth-child(2) .circle {
  background: url("https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat 50% 50% / cover;
  bottom: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
}

.card:nth-child(3) .circle {
  background: url("https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat 50% 50% / cover;
  top: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
}

.card:nth-child(4) .circle {
  background: url("https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat 50% 50% / cover;
  top: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
}

.pr-52 {
  padding-right: 230px;
}

.pl-48 {
  padding-left: 230px;
}

.p-10 {
  padding: 40px;
  border: 12px solid white;
}

.fw-600 {
  font-weight: 600;
}

.container-service .services-section .card {
  width: 360px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  z-index: 10;
  transition: transform 0.8s;
  height: 100%;

}

.services-section .card .icon {
  width: 150px;
  height: 150px;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 80px;
  border-radius: 50%;
  color: var(--active-bg);
  /* border: 2px solid var(--active-bg); */
  margin: -75px auto 0;
}

.services-section .card .icon {
  width: 150px;
  height: 150px;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 80px;
  border-radius: 50%;
  color: var(--active-bg);
  /* border: 2px solid var(--active-bg); */
  margin: -75px auto 0;
}

.services-section .card h2 {
  height: 35px;
  width: 70%;
  text-align: center;
  font-size: 25px;
  margin: 25px auto;
  position: relative;
  background: #fff;
  line-height: 35px;
}

.services-section .card h2:after {
  content: '';
  width: 40px;
  height: 30px;
  background: linear-gradient(to right, #fff, var(--active-bg));
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: -1;
}

.services-section .card h2:before {
  content: '';
  width: 40px;
  height: 30px;
  background: linear-gradient(to right, var(--active-bg), #fff);
  position: absolute;
  left: -5px;
  bottom: -5px;
  z-index: -1;
}

.services-section .card p {
  font-size: 16px;
  margin: 35px 20px;
}

.buil-step:hover {
  transform: translateY(-20px);
}

.buil-step {
  background: #fff;
  border-radius: 20px;
  border: 1px solid rgb(233, 232, 232);
  padding: 1.5rem;
  transition: transform 0.8s;
}

.buil-step.step-2 {
  /* background-color: #c8d2ff  !important; */
}
</style>